<template>
  <v-container>
    <v-row align="center" no-gutters>
      <span class="text-h2">
        {{ environment.name }}
      </span>

      <v-spacer />

      <v-btn
        :to="{
          name: 'k8s',
          params: { envName: environment.name, id: environment.id },
        }"
        class="mr-6"
        color="primary darken-2"
        :disabled="loading || !canViewCore"
        large
        elevation="10"
      >
        {{ labels.k8s }}
      </v-btn>
      <v-btn
        :to="{
          name: 'promotions',
          params: { envName: environment.name, environmentID: environment.id },
        }"
        class="mr-6"
        color="primary"
        :disabled="loading || !canViewPromotions"
        large
        elevation="10"
      >
        {{ labels.promotions }}
      </v-btn>
      <v-btn
        @click="$emit('launch-lobby')"
        class="mr-6"
        color="primary"
        :disabled="loading || !environment.lobby_url"
        :loading="lobbyLoading"
        large
        elevation="10"
      >
        {{ labels.lobby }}
      </v-btn>
    </v-row>

    <v-divider class="my-6" />

    <v-row no-gutters>
      <v-col cols=6 md=12>
        <v-row no-gutters>
          <v-skeleton-loader v-if="firstLoad" type="sentences" loading />
          <span
            v-else
            v-html="description"
            class="text-h6 font-weight-thin"
          />
        </v-row>
        <v-row no-gutters class="py-3">
          <div v-show="environment.core_version">
          <span class="text-h5 font-weight-thin pr-6">
            {{ labels.gsCoreVersion }}:
          </span>
          <v-chip dark color="primary">
            {{ environment.core_version }}
          </v-chip>
          <v-row no-gutters class="py-3"></v-row>
          <span class="text-h5 font-weight-thin pr-6">
            {{ labels.kolyseoVersion }}:
          </span>
          <v-chip dark color="primary">
            1.0.0
          </v-chip>
          </div>
          <v-spacer />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-progress-circular
                v-bind="attrs" v-on="on"
                :rotate="360"
                size="100"
                :loading="loading"
                width="15"
                :value="percentageInstalled"
                color="primary"
              >
              <span v-if="environment">
                {{ gameInstanceCount }}/{{ environment.max_games || "inf" }}
              </span>
              </v-progress-circular>
            </template>
            <span v-if="environment">
              {{ gameInstanceCount }} installed
              out of a maximum of {{ environment.max_games || "inf" }} games
            </span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { marked } from 'marked';
import { get } from 'lodash';
import { mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'environmentInfo',
  props: {
    canViewCore: {
      type: Boolean,
      default: false,
      required: false,
    },
    canViewPromotions: {
      type: Boolean,
      default: false,
      required: false,
    },
    environment: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    ...mapState('shared', [
      'firstLoad',
      'loading',
      'lobbyLoading',
    ]),
    ...mapState('fetch', [
      'gameInstanceCount',
    ]),
    description() {
      return marked(get(this.environment, 'description'));
    },
    percentageInstalled() {
      return (
        (100 * this.gameInstanceCount) / get(this.environment, 'max_games', 1)
      );
    },
  },
  data: () => ({
    labels,
  }),
};
</script>

<style scoped>
</style>
