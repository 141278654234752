var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('span',{staticClass:"text-h2"},[_vm._v(" "+_vm._s(_vm.environment.name)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-6",attrs:{"to":{
        name: 'k8s',
        params: { envName: _vm.environment.name, id: _vm.environment.id },
      },"color":"primary darken-2","disabled":_vm.loading || !_vm.canViewCore,"large":"","elevation":"10"}},[_vm._v(" "+_vm._s(_vm.labels.k8s)+" ")]),_c('v-btn',{staticClass:"mr-6",attrs:{"to":{
        name: 'promotions',
        params: { envName: _vm.environment.name, environmentID: _vm.environment.id },
      },"color":"primary","disabled":_vm.loading || !_vm.canViewPromotions,"large":"","elevation":"10"}},[_vm._v(" "+_vm._s(_vm.labels.promotions)+" ")]),_c('v-btn',{staticClass:"mr-6",attrs:{"color":"primary","disabled":_vm.loading || !_vm.environment.lobby_url,"loading":_vm.lobbyLoading,"large":"","elevation":"10"},on:{"click":function($event){return _vm.$emit('launch-lobby')}}},[_vm._v(" "+_vm._s(_vm.labels.lobby)+" ")])],1),_c('v-divider',{staticClass:"my-6"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","md":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.firstLoad)?_c('v-skeleton-loader',{attrs:{"type":"sentences","loading":""}}):_c('span',{staticClass:"text-h6 font-weight-thin",domProps:{"innerHTML":_vm._s(_vm.description)}})],1),_c('v-row',{staticClass:"py-3",attrs:{"no-gutters":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.environment.core_version),expression:"environment.core_version"}]},[_c('span',{staticClass:"text-h5 font-weight-thin pr-6"},[_vm._v(" "+_vm._s(_vm.labels.gsCoreVersion)+": ")]),_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.environment.core_version)+" ")]),_c('v-row',{staticClass:"py-3",attrs:{"no-gutters":""}}),_c('span',{staticClass:"text-h5 font-weight-thin pr-6"},[_vm._v(" "+_vm._s(_vm.labels.kolyseoVersion)+": ")]),_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v(" 1.0.0 ")])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"rotate":360,"size":"100","loading":_vm.loading,"width":"15","value":_vm.percentageInstalled,"color":"primary"}},'v-progress-circular',attrs,false),on),[(_vm.environment)?_c('span',[_vm._v(" "+_vm._s(_vm.gameInstanceCount)+"/"+_vm._s(_vm.environment.max_games || "inf")+" ")]):_vm._e()])]}}])},[(_vm.environment)?_c('span',[_vm._v(" "+_vm._s(_vm.gameInstanceCount)+" installed out of a maximum of "+_vm._s(_vm.environment.max_games || "inf")+" games ")]):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }